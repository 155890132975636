import '../App.css';
import { Link, Typography } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" style={{padding:10, marginTop: 'auto'}}>
      {'Copyright © '}
      <Link color="inherit" href="https://hltry.com/">
        hltry
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default Copyright;
