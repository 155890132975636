import { signUp, SignUpInput, SignUpOutput, AuthUser } from "@aws-amplify/auth";
import { AuthStandardAttributeKey, AuthVerifiableAttributeKey } from '@aws-amplify/core/internals/utils';
import { Email, Key, AlternateEmail } from "@mui/icons-material";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { AuthError } from "aws-amplify/auth";
import { useState } from "react";
import type { AuthCodeDeliveryDetails } from '../../node_modules/@aws-amplify/auth/dist/esm/types/models';

export default function SubscribeEnterEmail({ setStatus, setUser, setCodeDeliveryDetails }:{
        setStatus: React.Dispatch<React.SetStateAction<string>>, 
        setUser: React.Dispatch<React.SetStateAction<AuthUser|undefined>>,
        setCodeDeliveryDetails: React.Dispatch<React.SetStateAction<AuthCodeDeliveryDetails<AuthVerifiableAttributeKey> | undefined>>
    }) {
    const [emailValue, setEmailValue] = useState("");
    const [errorValue, setErrorValue] = useState("");

    async function handleSignUp({ username, password, options }: SignUpInput): Promise<SignUpOutput> 
    {
      return signUp({
        username: username.toLowerCase(),
        password,
        options: {
          ...options,
          userAttributes: {
            ...options?.userAttributes,
            email: options?.userAttributes?.email?.toLowerCase(),
          },
        },
      })
    }
  
    async function formSignUp() {
        setErrorValue("");
        let password = "dsfglkh98476wrelgadshfiQU#$%@#$%";
        let username = emailValue;
        let options = {userAttributes : {email: username}};
        let result = undefined;
        try {
            result = await handleSignUp({username, password, options});
        } catch (e) {
            let authError: AuthError = e as AuthError;
            /*
            console.log(authError);
            console.log("authError.name = " + authError.name);
            console.log("authError.cause = " + authError.cause);
            console.log("authError.message = " + authError.message);
            console.log("authError.recoverySuggestion = " + authError.recoverySuggestion);
            console.log("authError.underlyingError = " + authError.underlyingError);
            */
            if (authError.name == "UsernameExistsException") {
                setStatus("done");
                return;
            }
           setErrorValue(authError.message);
           return;
        }
        //console.log(result);
        if (result != undefined && result.isSignUpComplete == false && result.nextStep.signUpStep == "CONFIRM_SIGN_UP") {
            setStatus("confirm");
            setCodeDeliveryDetails(result.nextStep.codeDeliveryDetails);
            if (result.userId != undefined) {
                setUser({
                    username: username,
                    userId: result.userId
                });
            }
        }
        if (result != undefined && result.isSignUpComplete == false) {
            
        }
    }

    return (
        <>
            <Typography variant="h4" color="text.primary" align="center">Subscribe for <span style={{color:"#8ba725"}}>hltry</span> news and updates</Typography>
            <TextField id="outlined-basic" label="Enter your email" variant="outlined" required 
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <AlternateEmail />
                            </InputAdornment>
                        ),
                        }}
                        onChange={(e) => setEmailValue(e.target.value)}
                        style={{marginTop:35}}
                    />
            <Typography variant="body1" color="text.primary" align="left" style={{marginTop:0, padding:5, color:'red'}}>{errorValue}</Typography>
        <Button startIcon={<Email />} variant="contained" style={{margin:40, padding:10}} onClick={() => formSignUp()}>Subscribe for launch event</Button>
      </>
    );
}