import React, { useEffect, useState } from 'react';
import logo from '../assets/original_icon.png';
import '../App.css';

import Copyright from '../Shared/Copyright';

import '@aws-amplify/ui-react/styles.css';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

import awsExports from '../aws-exports';
import { Amplify } from 'aws-amplify';
import { AuthUser, deleteUser } from 'aws-amplify/auth';

import DeleteAccountEnterEmail from '../components/DeleteAccountEnterEmail';
import UnSubscribeConfirmed from '../components/UnSubscribeConfirmed';

Amplify.configure(awsExports);

function DeleteAccount() {

  const [userManual, setUser] = useState<AuthUser>();
  const [subscribeStatus, setSubscribeStatus] = useState("subscribe");

  const HandleDeleteStatus = () => {
    const { user, authStatus } = useAuthenticator((context) => [context.user]);
    //console.log("subscribeStatus = " + subscribeStatus);

    useEffect(() => {
      //console.log("authStatus = " + authStatus);

      async function deleteUserEffect() {
        try {
          //console.log("call delete user")
          await deleteUser();
          setSubscribeStatus("deleted");
        } catch (error) {
          console.log(error);
        }
      };

      //console.log("user = " + user);
      
      //if (user != undefined && user.userId > '') {
      //  deleteUserEffect();
      //}
      if (subscribeStatus == "done") {
        deleteUserEffect();
      }
    }, [user, authStatus]);
    
    return (<div></div>)
  }
  

  return (
    <div className="App" style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{width:'50%'}}>
        <img src={logo} alt="hltry" style={{width: '30%', height: 'auto', marginTop: 30}} />
      </div>
      <div style={{width:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {subscribeStatus === 'subscribe' ? <DeleteAccountEnterEmail setStatus={setSubscribeStatus} setUser={setUser} /> : null}
        {subscribeStatus === 'deleted' ? <UnSubscribeConfirmed /> : null}
      </div>
      
      <Authenticator.Provider>
        <HandleDeleteStatus></HandleDeleteStatus>
      </Authenticator.Provider>
      <Copyright />
    </div>
  );
}

export default DeleteAccount;
