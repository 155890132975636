import React, { useState } from 'react';

import '../App.css';
import Button from '@mui/material/Button';
import { InputAdornment, Link, TextField, Typography } from '@mui/material';
import { Email, Key, LockOpen } from '@mui/icons-material';
import {
  Link as RouterLink
} from 'react-router-dom';
import { AuthUser } from '@aws-amplify/auth';
import type { AuthCodeDeliveryDetails } from '../../node_modules/@aws-amplify/auth/dist/esm/types/models';
import { AuthVerifiableAttributeKey } from '@aws-amplify/core/internals/utils';
import { AuthError, confirmSignUp, ConfirmSignUpInput, ConfirmSignUpOutput } from 'aws-amplify/auth';


function SubscribeConfirm({ setStatus, user, codeDeliveryDetails }:{
  setStatus: React.Dispatch<React.SetStateAction<string>>, 
  user: AuthUser | undefined,
  codeDeliveryDetails: AuthCodeDeliveryDetails<AuthVerifiableAttributeKey> | undefined
}) {
  const [codeValue, setCodeValue] = useState("");
  const [errorValue, setErrorValue] = useState("");

  async function handleSignUp({ username, confirmationCode, options }: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> 
  {
    return confirmSignUp({
      username: username.toLowerCase(),
      confirmationCode
    })
  }

  async function formSignUp() {
    setErrorValue("");
    let username = user?.username;
    if (username == undefined) {
      setErrorValue("User not found");
      return;
    }
    let confirmationCode = codeValue;
    let result = undefined;
    try {
        result = await handleSignUp({username, confirmationCode});
    } catch (e) {
        let authError: AuthError = e as AuthError;
        /*
        console.log(authError);
        console.log("authError.name = " + authError.name);
        console.log("authError.cause = " + authError.cause);
        console.log("authError.message = " + authError.message);
        console.log("authError.recoverySuggestion = " + authError.recoverySuggestion);
        console.log("authError.underlyingError = " + authError.underlyingError);
        */
        if (authError.message.includes("Current status is CONFIRMED")) {
          setStatus("done");
          return;
        }
        
       setErrorValue(authError.message);
       return;
    }
    //console.log(result);
    if (result != undefined && result.isSignUpComplete == true) {
        setStatus("done");
    }
    
}

  return (
      <>
          <Typography variant="h4" color="text.primary" align="center">Confirm subscription</Typography>

          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>Your confirmation code is on the way. To confirm subscription, enter the code we emailed to {codeDeliveryDetails?.destination}. <br />It may take a minute to arrive.</Typography>

          <TextField id="outlined-basic" label="Enter code" variant="outlined" required 
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setCodeValue(e.target.value)}
            style={{marginTop:35}}
          />
          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:0, padding:5, color:'red'}}>{errorValue}</Typography>
          <Button startIcon={<LockOpen />} variant="contained" style={{margin:40, padding:10}} onClick={() => formSignUp()}>Confirm subscribtion</Button>
      </>
  );
}

export default SubscribeConfirm;
