import React, { useState } from 'react';

import '../App.css';
import Button from '@mui/material/Button';
import { InputAdornment, Link, TextField, Typography } from '@mui/material';


function UnSubscribeConfirmed() {

  return (
      <>
          <Typography variant="h4" color="text.primary" align="center">UnSubscribed!</Typography>

          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>Hope to see you soon again</Typography>

      </>
  );
}

export default UnSubscribeConfirmed;
