import React from 'react';
import logo from '../assets/original_icon.png';
import '../App.css';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';
import { Email, Info } from '@mui/icons-material';
import {
  Link as RouterLink
} from 'react-router-dom';
import Copyright from '../Shared/Copyright';


function Main() {
  return (
    <div className="App" style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <img src={logo} alt="hltry" style={{width: '30%', height: 'auto'}} />
        <Typography variant="h4" color="text.primary" align="center">
          Welcome to <span style={{color:"#8ba725"}}>hltry</span> - your health tracking app all-in-one!
        </Typography>
        <Typography variant="body1" color="text.primary" align="center" style={{marginTop:50, padding:10}}>as it's still in development for now you can </Typography>
        <Button startIcon={<Email />} variant="contained" style={{padding:10}} component={RouterLink} to="/subscribe">Subscribe for news</Button>
        <Typography variant="body1" color="text.primary" align="center" style={{padding:10}}>and read more </Typography>
        <Button startIcon={<Info />} variant="contained" style={{padding:10, marginBottom: 'auto'}} component={RouterLink} to="/about">About hltry</Button>
      
      <Copyright />
    </div>
  );
}

export default Main;
