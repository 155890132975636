import { signUp, SignUpInput, SignUpOutput, AuthUser } from "@aws-amplify/auth";
import { AuthStandardAttributeKey, AuthVerifiableAttributeKey } from '@aws-amplify/core/internals/utils';
import { Email, Key, AlternateEmail } from "@mui/icons-material";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { AuthError, signIn, SignInInput, SignInOutput } from "aws-amplify/auth";
import { useState } from "react";
import type { AuthCodeDeliveryDetails } from '../../node_modules/@aws-amplify/auth/dist/esm/types/models';

export default function DeleteAccountEnterEmail({ setStatus, setUser }:{
        setStatus: React.Dispatch<React.SetStateAction<string>>, 
        setUser: React.Dispatch<React.SetStateAction<AuthUser|undefined>>
    }) {
    const [emailValue, setEmailValue] = useState("");
    const [errorValue, setErrorValue] = useState("");

    async function handleSignIn({ username, password }: SignInInput): Promise<SignInOutput> 
    {
      return signIn({
        username: username.toLowerCase(),
        password
      })
    }
  
    async function formSignIn() {
        setErrorValue("");
        let password = "dsfglkh98476wrelgadshfiQU#$%@#$%";
        let username = emailValue;
        let result = undefined;
        try {
            result = await handleSignIn({username, password});
        } catch (e) {
            let authError: AuthError = e as AuthError;
            /*
            console.log(authError);
            console.log("authError.name = " + authError.name);
            console.log("authError.cause = " + authError.cause);
            console.log("authError.message = " + authError.message);
            console.log("authError.recoverySuggestion = " + authError.recoverySuggestion);
            console.log("authError.underlyingError = " + authError.underlyingError);
            */
            if (authError.name == "UsernameExistsException") {
                setStatus("done");
                return;
            }
           setErrorValue(authError.message);
           return;
        }
        //console.log(result);
        if (result != undefined && result.isSignedIn == true) {
            setStatus("done");
        }
        if (result != undefined && result.isSignedIn == false && result.nextStep.signInStep == "CONFIRM_SIGN_UP") {
            setErrorValue("This email address has not been confirmed yet. It will be deleted in few days.");
        }
    }

    return (
        <>
            <Typography variant="h4" color="text.primary" align="center">Delete your account from <span style={{color:"#8ba725"}}>hltry</span></Typography>
            <TextField id="outlined-basic" label="Enter your email" variant="outlined" required 
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <AlternateEmail />
                            </InputAdornment>
                        ),
                        }}
                        onChange={(e) => setEmailValue(e.target.value)}
                        style={{marginTop:35}}
                    />
            <Typography variant="body1" color="text.primary" align="left" style={{marginTop:0, padding:5, color:'red'}}>{errorValue}</Typography>
        <Button startIcon={<Email />} variant="contained" style={{margin:40, padding:10}} onClick={() => formSignIn()}>Request to delete</Button>
      </>
    );
}