import React, { useState } from 'react';
import logo from '../assets/original_icon.png';
import '../App.css';
import Button from '@mui/material/Button';
import { InputAdornment, Link, TextField, Typography } from '@mui/material';
import { Email, Info, Key } from '@mui/icons-material';
import {
  Link as RouterLink
} from 'react-router-dom';
import Copyright from '../Shared/Copyright';

import '@aws-amplify/ui-react/styles.css';

import { Authenticator, useAuthenticator, useTheme, View, Text, Heading } from '@aws-amplify/ui-react';
import { AuthStandardAttributeKey, AuthVerifiableAttributeKey } from '@aws-amplify/core/internals/utils';
import type { AuthCodeDeliveryDetails } from '../../node_modules/@aws-amplify/auth/dist/esm/types/models';

import awsExports from '../aws-exports';
import { Amplify } from 'aws-amplify';
import { AuthUser } from 'aws-amplify/auth';

import { useNavigate } from 'react-router-dom';

import SubscribeEnterEmail from '../components/SubscribeEnterEmail'
import SubscribeConfirm from '../components/SubscribeConfirm'
import SubscribeConfirmed from '../components/SubscribeConfirmed'

Amplify.configure(awsExports);

function Subscribe() {

  const navigate = useNavigate();

  const HandleSignUp = () => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    //console.log("user = " + user);
    if (user != undefined && user.userId > '') {
      navigate('/subscribe-success');
    }
    return (<div></div>)
  }

  

  const [user, setUser] = useState<AuthUser>();
  const [subscribeStatus, setSubscribeStatus] = useState("subscribe");
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState<AuthCodeDeliveryDetails<AuthVerifiableAttributeKey>>();
  

  return (
    <div className="App" style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{width:'50%'}}>
        <img src={logo} alt="hltry" style={{width: '30%', height: 'auto', marginTop: 30}} />
      </div>
      <div style={{width:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {subscribeStatus === 'subscribe' ? <SubscribeEnterEmail setStatus={setSubscribeStatus} setUser={setUser} setCodeDeliveryDetails={setCodeDeliveryDetails} /> : null}
        {subscribeStatus === 'confirm' ? <SubscribeConfirm setStatus={setSubscribeStatus} user={user} codeDeliveryDetails={codeDeliveryDetails} /> : null}
        {subscribeStatus === 'done' ? <SubscribeConfirmed /> : null}
      </div>
      
      <Authenticator.Provider>
        <HandleSignUp></HandleSignUp>
      </Authenticator.Provider>
      <Copyright />
    </div>
  );
}

export default Subscribe;
