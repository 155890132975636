import React from 'react';
import image1 from '../assets/openart-image_raw_logo.png';
import image2 from '../assets/openart-image_raw_community.png';

import '../App.css';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';
import { Email, Info } from '@mui/icons-material';
import {
  Link as RouterLink
} from 'react-router-dom';
import Copyright from '../Shared/Copyright';


function About() {
  return (
    <div className="App" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width:'50%'}}>
          <img src={image1} alt="hltry" style={{width: '70%', height: 'auto'}} />
          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>Welcome to hltry - your go-to pal for living a healthier life! Our app is all about making wellness easy and enjoyable for you.</Typography>
          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>At hltry, we get that health is about way more than just the physical stuff. That's why our app lets you effortlessly track all the important things like your weight, daily steps, sleep patterns, and even what you're munching on. Plus, it's super user-friendly, so you can stay on top of your progress without any hassle.</Typography>
          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>But wait, there's more! Hltry also sends you friendly little reminders to keep you motivated and on track. Whether it's time for a water break, a stretching sesh, or some deep breaths, we've got your back with personalized nudges.</Typography>

          <img src={image2} alt="hltry" style={{width: '70%', height: 'auto'}} />

          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>And the best part? You're joining a awesome community of health enthusiasts just like you! You can swap tips, share stories, and find inspiration from others on their own wellness journeys. It's like having a whole squad of health buddies cheering you on!</Typography>
          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>So why not give hltry a try? We're here to make living well fun, simple, and totally doable for you. Let's get this healthy party started!</Typography>

        </div>

        <Button startIcon={<Email />} variant="contained" style={{margin:40, padding:10}} component={RouterLink} to="/subscribe">Subscribe for launch event</Button>
      
      <Copyright />
    </div>
  );
}

export default About;
